import {Link} from "react-router-dom";
import React, {useState} from "react";

export interface HomeProps {
    beans: string[]
    onBeanAdded: (newBean: string) => void
}

export default function Home(props: HomeProps) {

    const [newBeanInput, setNewBeanInput] = useState<string>("")

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        }}>
            { props.beans.map(bean => {
                return <Link to={`/${bean.replaceAll(" ", "-")}`} style={{ textDecoration: 'none' }}>
                    { bean }
                </Link>
            })}
            <div style={{ marginBottom: 20 }} />
            <input
                type="text"
                value={newBeanInput}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setNewBeanInput(event.target.value)
                }}
            />
            <button onClick={() => props.onBeanAdded(newBeanInput)}>
                Add bean
            </button>
        </div>
    )
}