import React, {useState} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Bean from './pages/Bean';
import Home from "./pages/Home";
import Menu from './pages/Menu';
import Brackets from './pages/Brackets';

function App() {

    const [beans, setBeans] = useState(["shmol bean", "shmollest bean"])

    return (
        <Router>
            <Routes>
                <Route path={'/beans'} element={
                    <Home
                        beans={beans}
                        onBeanAdded={(newBean) =>
                            setBeans((oldBeans) => [newBean, ...oldBeans]
                        )}
                    />}
                />
                { beans.map(bean => {
                    return <Route
                        path={`/${bean.replaceAll(" ", "-")}`}
                        element={<Bean title={bean}/>}
                    />
                })}
                <Route path={'/'} element={
                    <Menu />
                }/>
                <Route path={'/brackets'} element={
                    <Brackets />
                }/>
            </Routes>
        </Router>
    )
}

export default App;
