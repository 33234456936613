import Button from "../components/Button"
import "./Menu.css"

export default function Menu() {

    return (
        <div>
            <h1>BierTurnier</h1>

            <div className="menu">
                <Button title="Spectate" />
                <Button title="Join Team" />
                <Button title="Create Team" />
            </div>

        </div>
    )
}