import {Link} from "react-router-dom";

export interface BeansProps {
    title: string
}

export default function Bean(props: BeansProps) {

    return (
        <div style={{
            display: "flex",
            flexDirection: "column"
        }}>
            <Link to={"/"} style={{ margin: 20 }}>
                { "<- Back to Main" }
            </Link>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            }}>
                <img
                    src={"beanicon2.png"}
                    alt="icon"
                    style={{
                        maxHeight: "40px",
                        marginLeft: 20,
                        marginRight: 10
                    }}
                />
                <strong>{ props.title }</strong>
            </div>
        </div>
    )
}